import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { StatusTable } from './StatusTable';
import { IdvAttemptTable } from './IdvAttemptTable';
import { AddressTable } from './AddressTable';
import TransactionDetails from '../transactionDetails/TransactionDetails';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import { parseDateTime } from '../utils/dateTime';
import { AccountStatusUpdates } from './AccountStatusUpdates';
import { adminInCorrectGroup, LOCK_USER } from '../../utils/AdminUtils';

const CheckingOverview = (props) => {
  const { debit, id, adminGroups, debitFetch } = props;
  const account = debit.account;
  const canLockAccount = adminInCorrectGroup(adminGroups.groups, LOCK_USER);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={5} xl={5}>
          <Typography variant="subtitle1" gutterBottom>
            Account Status
          </Typography>
          <StatusTable id={id} account={account} adminGroups={adminGroups} />
        </Grid>
        <Grid item xs={12} sm={12} lg={7} xl={7}>
          {account.idvAttempts && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                IDV Info
              </Typography>
              <IdvAttemptTable idvAttempts={account.idvAttempts} />
            </>
          )}
          <br />
          <br />
          <Typography variant="subtitle1" gutterBottom>
            User Address
          </Typography>
          <AddressTable address={account.address} />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems={'flex-end'}>
        <Grid item>
          <Typography variant="subtitle1" gutterBottom>
            Account Status History
          </Typography>
        </Grid>
        {canLockAccount && (
          <AccountStatusUpdates account={account} debitFetch={debitFetch} />
        )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Paper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Changed At</TableCell>
                  <TableCell>Changed By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {account?.accountStatusHistory.map((accountStatusChange) => (
                  <TableRow key={accountStatusChange.changedAt}>
                    <TableCell>{accountStatusChange.status}</TableCell>
                    <TableCell>{accountStatusChange.reason}</TableCell>
                    <TableCell>
                      {parseDateTime(accountStatusChange.changedAt)}
                    </TableCell>
                    <TableCell>{accountStatusChange.changedBy}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <br />
          <br />
          <Typography variant="subtitle1" gutterBottom>
            Recent transactions
          </Typography>
          <TransactionDetails {...props} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export { CheckingOverview };
